<template>
    <div>
        <Pane />
        <a-card class="container">
            <div class="right" style="padding-bottom: 8px">
                <a-space>
                    <a-button type="primary" @click="download">导出表格</a-button>
                </a-space>
            </div>
            <div class="title">建筑施工类</div>
            <a-table bordered :data-source="list?.building" :pagination="false">
            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index + 1 }}
                </template>
            </a-table-column>
            <a-table-column title="风险点" data-index="point"></a-table-column>
            <a-table-column title="风险描述" data-index="riskDesc"></a-table-column>
            <a-table-column title="风险类型"  data-index="type">
                <template slot-scope="type">
                <DataDictFinder dictType="qualitySafety.riskType" :dictValue="type" />
                </template>
            </a-table-column>
            <a-table-column title="风险等级" data-index="level">
                <template slot-scope="level">
                <DataDictFinder dictType="qualitySafety.riskLevel" :dictValue="level" />
                </template>
            </a-table-column>
            <a-table-column title="管控措施" data-index="measure"></a-table-column>
            <a-table-column title="责任人" data-index="managerName"></a-table-column>
            <a-table-column title="责任部门" data-index="deptName"></a-table-column>
            <a-table-column title="备注" data-index="remark"></a-table-column>
            </a-table>
            <div class="title">办公日常类</div>
            <a-table bordered :data-source="list?.officeRoutine" :pagination="false">
            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index + 1 }}
                </template>
            </a-table-column>
            <a-table-column title="风险点" data-index="point"></a-table-column>
            <a-table-column title="风险描述" data-index="riskDesc"></a-table-column>
            <a-table-column title="风险类型"  data-index="type">
                <template slot-scope="type">
                <DataDictFinder dictType="qualitySafety.riskType" :dictValue="type" />
                </template>
            </a-table-column>
            <a-table-column title="风险等级" data-index="level">
                <template slot-scope="level">
                <DataDictFinder dictType="qualitySafety.riskLevel" :dictValue="level" />
                </template>
            </a-table-column>
            <a-table-column title="管控措施" data-index="measure"></a-table-column>
            <a-table-column title="责任人" data-index="managerName"></a-table-column>
            <a-table-column title="责任部门" data-index="deptName"></a-table-column>
            <a-table-column title="备注" data-index="remark"></a-table-column>
            </a-table>
            <div class="center">
                <a-space>
                    <a-button @click="$close($route.path)">关闭</a-button>
                    <a-button v-if="canProcess" type="primary" :loading="loading" @click="handleSubmit">发起审批</a-button>
                </a-space>
            </div>
        </a-card>
    </div>
</template>
  
<script>
import request from "@/api/request";
import download from '@/api/download'
import organization from "@/mixins/organization";
import { saveAs } from "file-saver";
function getDetail(id) {
  return request({
    url: "/office-service/quality/risk/task/queryRiskList/" + id,
  });
}
function raiseProgress(id) {
    return request({
        url: "/office-service/quality/risk/task/raiseProgress/" + id,
        method: "post",
    });
}

function downloadTable(id) {
    return download({
        url: "/office-service/quality/risk/task/exportList/" + id,
        method: "post",
    });
}
export default {
    name: "technicalQualityScoreAdd",
    mixins: [organization],


    data() {
        return {
            loading: false,
            list: [],
            canProcess: false
        };
    },
    mounted() {
        const { query } = this.$route;
        const { id, status } = query || {};
        if(status == 'completed') {
            this.canProcess = true;
        }
        getDetail(id).then(res => {
            this.list = res;
        });
    },
    methods: {
        download() {
            const { query } = this.$route;
            const { id } = query || {};
            downloadTable(id).then(blob => {
                saveAs(blob, "风险评估汇总清单.xlsx");
            });
        },
        handleSubmit() {
            const { query } = this.$route;
            const { id } = query || {};
            this.loading = true;
            raiseProgress(id)
            .then(() => {
                this.$close(this.$route.path);
            })
            .finally(() => {
                this.loading = false;
            });
        }
    },
};
</script>
  
  
<style lang="less" scoped>
.container {
    padding: 12px;
}

.center {
    margin-top: 80px;
    margin-bottom: 80px;
}

.title {
  font-size: 14px;
  color: #1890ff;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-left: 8px;
}
</style>